// formValidationConfig
// флаги
var is_validation_extended = false,
is_validation_translated = false,

// игнор элементов по селектору
form_ignore_selectos = ':hidden',

// классы
form_class = 'js-form',
form_selector = '.'+form_class,

form_class__submit = 'js-form-submit',
form_selector__submit = '.'+form_class__submit,

form_class__reset = 'js-form-reset',
form_selector__reset = '.'+form_class__reset,

form_selector__input_file = 'input[type="file"]',
form_selector__js_file = '.js-form-file',
form_selector__js_file__input = form_selector__js_file + '__input',
form_selector__js_file__result = form_selector__js_file + '__result',

form_class__control_holder = 'control-holder',
form_selector__control_holder = '.'+form_class__control_holder,

form_class__valid = 'form--valid',
form_class__no_valid = 'form--no-valid',

form_class__error = 'has-error',
form_class__success = 'has-success';

wHTML.prototype.formValidationConfig = function() {
	// если плагин еще не расширялся
	if (is_validation_extended) {
		return;
	}

	// расширяем валидатор
	$.extend($.validator.defaults, {
		// переписываем дефолтные значения
		errorClass: form_class__error,
		validClass: form_class__success,
		controlHolder: form_selector__control_holder,
		inputFile: form_selector__input_file,
		ignore: form_ignore_selectos,

		// метод подсветки ошибок
		highlight: function(element, errorClass, validClass) {
			var $el;
			if (element.type === "radio") {
				$el = this.findByName(element.name);
			} else {
				var $el = $(element);
			}

			$el.add($el.closest(form_selector__control_holder))
				.addClass(errorClass)
				.removeClass(validClass);
		},

		// метод отключения подсветки ошибок
		unhighlight: function(element, errorClass, validClass) {
			var $el;
			if (element.type === "radio") {
				$el = this.findByName(element.name);
			} else {
				var $el = $(element);
			}

			$el.add($el.closest(form_selector__control_holder))
				.removeClass(errorClass)
				.addClass(validClass);
		},

		// обработчик ошибок
		invalidHandler: function(form, validator) {
			$(this)
				.addClass(form_class__no_valid)
				.data('validator').focusInvalid();
		},

		// обработчик сабмита
		submitHandler: function(form) {
			var $currentForm = $(form);
			$currentForm.removeClass(form_class__no_valid).addClass(form_class__valid);
			_self.formValidationOnSubmit($currentForm);
		}
	});

	$.extend($.validator.prototype, {
		defaultMessage: function(element, rule) {
			var method = rule.method;
			// WezomFix
			var method_name = _formGetMethodMsgName(element, method);
			var message = this.findDefined(
				this.customMessage(element.name, method),
				this.customDataMessage(element, method),
				// title is never undefined, so handle empty string as undefined
				!this.settings.ignoreTitle && element.title || undefined,
				$.validator.messages[method_name],
				"<strong>Warning: No message defined for " + element.name + "</strong>"
				),
				theregex = /\$?\{(\d+)\}/g;
			if ( typeof message === "function" ) {
				message = message.call( this, rule.parameters, element );
			} else if ( theregex.test( message ) ) {
				message = $.validator.format( message.replace( theregex, "{$1}" ), rule.parameters );
			}

			return message;
		}
	});

	$.extend($.validator.methods, {

		/* Старая валидация
		email: function (value, element) {
			return tvarhis.optional(element) || /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(value);
		},*/

		email: function (value, element) {
			var pattern = /^(([a-zA-Z0-9&+-=_])+((\.([a-zA-Z0-9&+-=_]){1,})+)?){1,64}@([a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,6}$/;
			return this.optional(element) || pattern.test(value);
		},

		password: function(value, element) {
			return this.optional(element) || /^\S.*$/.test(value);
		},

		filesize: function(value, element, param) {
			var kb = 0;
			for (var i = 0; i < element.files.length; i++) {
				kb += element.files[i].size;
			}
			return this.optional(element) || (kb / 1024 <= param);
		},

		filesizeEach: function(value, element, param) {
			var flag = true;
			for (var i = 0; i < element.files.length; i++) {
				if (element.files[i].size / 1024 > param) {
					flag = false;
					break;
				}
			}
			return this.optional(element) || (flag);
		},

		filetype: function(value, element, param) {
			var result;
			param = typeof param === "string" ? param.replace(/,/g, "|") : "png|jpe?g|doc|pdf|gif|zip|rar|tar|html|swf|txt|xls|docx|xlsx|odt";
			if (element.multiple) {
				var files = element.files;
				for (var i = 0; i < files.length; i++) {
					var value = files[i].name;
					result = this.optional(element) || value.match(new RegExp(".(" + param + ")$", "i"));
					if (result === null) {
						break;
					}
				}
			} else {
				var result = this.optional(element) || value.match(new RegExp("\\.(" + param + ")$", "i"));
			}
			return result;
		},

		or: function(value, element, param) {
			var $module = $(element).parents('.js-form');
			return $module.find('.' + param + ':filled').length;
		},

		word: function(value, element) {
			return this.optional(element) || /^[a-zA-Zа-яА-ЯіІїЇєёЁЄґҐĄąĆćĘęŁłŃńÓóŚśŹźŻż\'\`\- ]*$/.test(value);
		},

		login: function(value, element) {
			return this.optional(element) || /^[0-9a-zA-Zа-яА-ЯіІїЇєЄёЁґҐĄąĆćĘęŁłŃńÓóŚśŹźŻż][0-9a-zA-Zа-яА-ЯіІїЇєЄґҐĄąĆćĘęŁłŃńÓóŚśŹźŻż\-\._]+$/.test(value);
		},

		site: function(value, element) {
			return this.optional(element) || /^.+\..{2,30}$/.test(value)
		},

		url: function(value, element) {
			return this.optional(element) || /^((?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
		},

		phoneUA: function(value, element, param) {
			return this.optional(element) || /^(((\+?)(38))\s?)?(([0-9]{3})|(\([0-9]{3}\)))(\-|\s)?(([0-9]{3})(\-|\s)?([0-9]{2})(\-|\s)?([0-9]{2})|([0-9]{2})(\-|\s)?([0-9]{2})(\-|\s)?([0-9]{3})|([0-9]{2})(\-|\s)?([0-9]{3})(\-|\s)?([0-9]{2}))$/.test(value);
		},

		phoneRU: function(value, element, param) {
			return this.optional(element) || /^(((\+?)(7|8))\s?)?(([0-9]{3})|(\([0-9]{3}\)))(\-|\s)?(([0-9]{3})(\-|\s)?([0-9]{2})(\-|\s)?([0-9]{2})|([0-9]{2})(\-|\s)?([0-9]{2})(\-|\s)?([0-9]{3})|([0-9]{2})(\-|\s)?([0-9]{3})(\-|\s)?([0-9]{2}))$/.test(value);
		},

		phone: function(value, element, param) {
			return this.optional(element) || /^[0-9\+\-\(\)\s]{4,30}$/.test(value);
		},

		validTrue: function(value, element, param) {
			if ($(element).data('valid') === true) {
				return true;
			} else {
				return false;
			}
		}
	});

	$.extend($.validator.prototype, {
		init: function() {
			this.labelContainer = $(this.settings.errorLabelContainer);
			this.errorContext = this.labelContainer.length && this.labelContainer || $(this.currentForm);
			this.containers = $(this.settings.errorContainer).add(this.settings.errorLabelContainer);
			this.submitted = {};
			this.valueCache = {};
			this.pendingRequest = 0;
			this.pending = {};
			this.invalid = {};
			this.reset();

			var groups = (this.groups = {}),
				rules;
			$.each(this.settings.groups, function(key, value) {
				if (typeof value === "string") {
					value = value.split(/\s/);
				}
				$.each(value, function(index, name) {
					groups[name] = key;
				});
			});
			rules = this.settings.rules;
			$.each(rules, function(key, value) {
				rules[key] = $.validator.normalizeRule(value);
			});

			function delegate2(event) {
				// WezomFix
				var validator, form, eventType;
				form = this.form;

				if (!form) {
					form = $(this).closest("div[data-form='true']").get(0);
				}
				validator = $.data(form, "validator");
				eventType = "on" + event.type.replace(/^validate/, "");
				/*this.settings = validator.settings;
				if (this.settings[eventType] && !this.is(this.settings.ignore)) {
					this.settings[eventType].call(validator, this[0], event);
				}*/
				var settings = validator.settings;
				if (settings[eventType] && !$(this).is(settings.ignore)) {
					settings[eventType].call(validator, this, event);
				}
			}

			$(this.currentForm)
				.on("focusin.validate focusout.validate keyup.validate",
					":text, [type='password'], [type='file'], select, textarea, [type='number'], [type='search'], " +
					"[type='tel'], [type='url'], [type='email'], [type='datetime'], [type='date'], [type='month'], " +
					"[type='week'], [type='time'], [type='datetime-local'], [type='range'], [type='color'], " +
					"[type='radio'], [type='checkbox']", delegate2)
				// Support: Chrome, oldIE
				// "select" is provided as event.target when clicking a option
				.on("click.validate", "select, option, [type='radio'], [type='checkbox']", delegate2);

			if (this.settings.invalidHandler) {
				$(this.currentForm).on("invalid-form.validate", this.settings.invalidHandler);
			}

			// Add aria-required to any Static/Data/Class required fields before first validation
			// Screen readers require this attribute to be present before the initial submission http://www.w3.org/TR/WCAG-TECHS/ARIA2.html
			$(this.currentForm).find("[required], [data-rule-required], .required").attr("aria-required", "true");
		}
	});

	// переписываем ститческое правило для работы с дивами
		$.validator.staticRules = function(element) {
			// WezomFix
			if (element.form) {
				validator = $.data(element.form, "validator");
			} else {
				validator = $.data($(element).closest("div[data-form='true']").get(0), "validator");
			}

			var rules = {},
				//validator = $.data(element.form, "validator");
				validator = validator; // WezomFix

			if (validator.settings.rules) {
				rules = $.validator.normalizeRule(validator.settings.rules[element.name]) || {};
			}
			return rules;
		};


	// включаем флаг, что уже расширили плагин
	is_validation_extended = true;

	// если плагин уже бл переведен или глобального объекта переводву нету - выходим
	if (is_validation_translated || window.validationTranslate === undefined) {
		return false;
	}
	// иначе делаем перевод
	var translateMessages = {};
	for (var key in validationTranslate) {
		var value = validationTranslate[key];
		switch(key) {
			case 'maxlength':
			case 'maxlength_checker':
			case 'maxlength_select':

			case 'minlength':
			case 'minlength_checker':
			case 'minlength_select':

			case 'rangelength':
			case 'rangelength_checker':
			case 'rangelength_select':

			case 'range':
			case 'min':
			case 'max':

			case 'filetype':
			case 'filesize':
			case 'filesizeEach':
			case 'pattern':
				translateMessages[key] = $.validator.format(value);
				break;
			default:
				translateMessages[key] = value;
		}
	}
	$.extend($.validator.messages, translateMessages);

	is_validation_translated = true;
};

$.extend( $.fn, {

	// http://jqueryvalidation.org/rules/
	rules: function( command, argument ) {
		var element = this[ 0 ],
			settings, staticRules, existingRules, data, param, filtered;

		// If nothing is selected, return empty object; can't chain anyway
		if ( element == null) {

			return;
		}

		if ( command ) {
			settings = $.data( element.form, "validator" ).settings;
			staticRules = settings.rules;
			existingRules = $.validator.staticRules( element );
			switch ( command ) {
				case "add":
					$.extend( existingRules, $.validator.normalizeRule( argument ) );

					// Remove messages from rules, but allow them to be set separately
					delete existingRules.messages;
					staticRules[ element.name ] = existingRules;
					if ( argument.messages ) {
						settings.messages[ element.name ] = $.extend( settings.messages[ element.name ], argument.messages );
					}
					break;
				case "remove":
					if ( !argument ) {
						delete staticRules[ element.name ];
						return existingRules;
					}
					filtered = {};
					$.each( argument.split( /\s/ ), function( index, method ) {
						filtered[ method ] = existingRules[ method ];
						delete existingRules[ method ];
						if ( method === "required" ) {
							$( element ).removeAttr( "aria-required" );
						}
					} );
					return filtered;
			}
		}

		data = $.validator.normalizeRules(
			$.extend(
				{},
				$.validator.classRules( element ),
				$.validator.attributeRules( element ),
				$.validator.dataRules( element ),
				$.validator.staticRules( element )
			), element );

		// Make sure required is at front
		if ( data.required ) {
			param = data.required;
			delete data.required;
			data = $.extend( { required: param }, data );
			$( element ).attr( "aria-required", "true" );
		}

		// Make sure remote is at back
		if ( data.remote ) {
			param = data.remote;
			delete data.remote;
			data = $.extend( data, { remote: param } );
		}

		return data;
	}
} );
