wHTML.prototype.trigger = function() {
	var triggerBuffer = [];
	var popupOpen = false;
	var showPopup = function () {
		$.ajax({
			url: '/popup/autoform',
			type: 'post',
			data: {
				id: triggerBuffer.shift()
			},
			success: function (response) {
				if (response) {
					var $html = $(response);
					$.magnificPopup.open({
						items: {
							src: $html
						},
						type: 'inline',
						removalDelay: 300,
						mainClass: 'zoom-in',
						callbacks: {
							open: function() {
								_self.formValidation($html);
							},
							afterClose: function() {
								if (triggerBuffer.length) {
									setTimeout(function () {
										showPopup();
									}, 1000);
								} else {
									popupOpen = false;
								}
							}
						}
					});
				}
			}
		});
	};

	$('.js-trigger').each(function () {
		var el = this;
		var $el = $(el);
		var id = this.id;

		if (id) {
			var observer;
			if (window.IntersectionObserver) {
				observer = new window.IntersectionObserver(function (entries) {
					if (entries[0].isIntersecting && !$el.data('trigger')) {
						$el.data('trigger', true);
						triggerBuffer.push(id);
						if (!popupOpen && triggerBuffer.length) {
							popupOpen = true;
							showPopup();
						}
					}
				}, {
					rootMargin: '0px',
					threshold: 0
				});
				observer.observe(el);
			} else {
				triggerBuffer.push(id);
				if (!popupOpen && triggerBuffer.length) {
					popupOpen = true;
					showPopup();
				}
			}
		}
	});
};
