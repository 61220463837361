wHTML.prototype.iframeResize = function() {
	var iframeResizeInit = function () {
		$('.js-iframe-resize').each(function () {
			$(this).css({
				transform: 'none'
			});
			var parentWidth = parseFloat($(this).parent().width());
			$(this).css({
				transform: 'scale(' + (parentWidth / 1920) + ')'
			});
		});
	};

	setTimeout(function () {
		iframeResizeInit();
	}, 500);

	$(window).on('resize', function () {
		setTimeout(function () {
			iframeResizeInit();
		}, 500);
	});
};
